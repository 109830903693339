// MARK: Used in V2

import { useLocation } from "@reach/router"
import classNames from "classnames"
import { StoryblokComponent, storyblokEditable } from "gatsby-source-storyblok"
import React, { useState, useEffect, useCallback, useRef } from "react"

import Modal from "../../../elements/Modal"
import Video from "../../../elements/V2/Video"

import { getUrlFromStoryblokLink } from "@utils/storyblok"
import { getBackgroundColorClass } from "@utils/V2/color"

const ModalContainer = ({
  blok,
}: Storyblok.BlokProps<Storyblok.ModalContainer>) => {
  const location = useLocation()
  const [open, setOpen] = useState(false)
  const [renderChildren, setRenderChildren] = useState(false)
  const timeoutRef = useRef(0)

  const handleOpen = useCallback(() => {
    window.clearTimeout(timeoutRef.current)
    setRenderChildren(true)
    setOpen(true)
  }, [])

  const handleClose = useCallback(() => {
    setOpen(false)
    timeoutRef.current = window.setTimeout(() => {
      setRenderChildren(false)
    }, 300)
  }, [])

  useEffect(() => {
    const timeOut = setTimeout(() => {
      const links = document.querySelectorAll(`a[href*="#${blok.id}"]`)

      const onLinkClick = (e: any) => {
        e.preventDefault()
        handleOpen()
      }

      if (links) {
        links.forEach((link) => link.addEventListener("click", onLinkClick))
      }

      return () => {
        if (links) {
          links.forEach((link) =>
            link.removeEventListener("click", onLinkClick)
          )
        }
        clearTimeout(timeOut)
      }
    }, 1000)
  }, [blok.id, handleOpen])

  useEffect(() => {
    if (blok.showOnLoad) {
      handleOpen()
    }
  }, [blok.showOnLoad, handleOpen])

  /**
   * Allows for default open state of modal by use of query params.
   * Value passed into query param to have this modal open by default
   * should be the modal id or be the openCriteria name and value matching
   **/

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const modal_open_query_param = urlParams.get(
      process.env.GATSBY_MODAL_OPEN_QUERY_PARAM || ""
    )

    const queryParamCriteria = blok.autoOpenCriteria?.find(
      (value) => urlParams.get(value.queryParamName) === value.queryParamValue
    )

    if (modal_open_query_param === blok.id || queryParamCriteria) {
      handleOpen()
    }
  }, [location, blok.id, handleOpen, blok.autoOpenCriteria])

  useEffect(() => {
    const timeoutCurrent = timeoutRef.current
    return () => {
      window.clearTimeout(timeoutCurrent)
    }
  }, [])
  return (
    <Modal
      open={open}
      handleClose={handleClose}
      explicitWidth={blok.width ? parseInt(blok.width) : undefined}
      closeIconOutside={blok.closeIconOutside}
      closeIconColor={blok.closeIconColor}
    >
      <div
        className={classNames(
          {
            "rounded overflow-hidden": blok.rounded,
          },
          blok.backgroundColor && getBackgroundColorClass(blok.backgroundColor)
        )}
        // @ts-ignore to have more strict typing compared to Storybloks generic object typing
        {...storyblokEditable(blok)}
      >
        <div className={classNames({ "p-40-v2 sm-v2:p-48-v2": blok.padding })}>
          {renderChildren &&
            blok.video &&
            blok.videoCoverImage &&
            blok.videoCoverImage.filename && (
              <Video
                video={
                  blok.video?.cached_url || blok.video?.url
                    ? getUrlFromStoryblokLink(blok.video)
                    : ""
                }
                aspectRatio="16:9"
                playbackMode="manual"
                playOnMobile={true}
                coverImage={blok.videoCoverImage.filename}
                className="grid place-content-center"
              />
            )}
          {renderChildren
            ? blok.content?.map((blok) => (
                <StoryblokComponent key={blok._uid} blok={blok} />
              ))
            : undefined}
        </div>
      </div>
    </Modal>
  )
}

export default ModalContainer
